















































import Vue from 'vue'
import Component from 'vue-class-component'

import Victual from '../model/victual'
import VictualApi from '../api/api'
import { victualFirebaseApi } from '../api/firebaseApi'
import VictualForm from '../components/VictualForm.vue'

@Component({
  components: { VictualForm }
})
export default class VictualCreate extends Vue {
  victualApi: VictualApi = victualFirebaseApi

  victual: Victual = {
    id: '',
    name: '',
    nutriments: {
      energyInKcal: 0,
      fats: 0,
      proteins: 0,
      carbs: 0
    },
    pricePer100g: 0,
    allergens: [],
    category: undefined,
    supermarketDepartment: undefined,
    defaultAmountInGrams: 100,
    units: []
  }

  victualValid = false

  async onCreate (): Promise<void> {
    if (!this.victualValid) {
      return
    }

    const victual = Object.assign({}, this.victual)
    const victualId = await this.victualApi.create(victual)

    await this.$router.push(`/victuals/${victualId}`)
  }

  async onCancel (): Promise<void> {
    await this.$router.go(-1)
  }

  onValidChanged (valid: boolean): void {
    this.victualValid = valid
  }
}
