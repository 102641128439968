
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import VictualApi from '../api/api'
import { victualFirebaseApi } from '../api/firebaseApi'

import RecipeApi from '../../recipe/api/api'
import { recipeFirebaseApi } from '@/recipe/api/firebaseApi'
import { VictualTracing } from '../model/victual'

@Component({})
export default class VictualUsage extends Vue {
  victualApi: VictualApi = victualFirebaseApi
  recipeApi: RecipeApi = recipeFirebaseApi

  @Prop()
  victualId!: string

  usedInRecipes: VictualTracing[] = [] 

  loading = true

  async created (): Promise<void> {
    this.loading = true
    this.usedInRecipes = []
    this.usedInRecipes = await this.victualApi.getUsageInRecipes(this.victualId)
    this.loading = false
  }

  async onMappingClick (recipeId: number): Promise<void> {
    await this.$router.push(`/recipes/${recipeId}`)
  }
}

