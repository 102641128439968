
























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import VictualUsage from './VictualUsage.vue'

import VictualApi from '../api/api'
import { victualFirebaseApi } from '../api/firebaseApi'
import { VictualTracing } from '../model/victual'

@Component({
  components: { VictualUsage }
})
export default class VictualDeleteIcon extends Vue {
  victualApi: VictualApi = victualFirebaseApi
  
  @Prop({ default: false })
  disabled!: boolean

  @Prop()
  victualId!: string

  @Prop()
  victualName!: string

  showDialog = false
  loading = false
  referencedRecipes: VictualTracing[] = []

  get deletePossible (): boolean {
    return !this.loading && this.referencedRecipes.length === 0
  }

  async onOpenDialog (): Promise<void> {
    this.loading = true
    this.showDialog = true
  
    this.referencedRecipes = await this.victualApi.getUsageInRecipes(this.victualId)

    this.loading = false
  }

  onAgree (event: Event): void {
    this.showDialog = false
    this.$emit('click', event)
  }
  
  onAbort (): void {
    this.showDialog = false
  }

  async toVictualInfo (): Promise<void> {
    await this.$router.push(`/victuals/${this.victualId}`)
  }
}
