

































































import Vue from 'vue'
import Component from 'vue-class-component'

import { VictualWithMeta } from '../model/victual'
import VictualApi from '../api/api'
import { victualFirebaseApi } from '../api/firebaseApi'
import VictualForm from '../components/VictualForm.vue'
import { userStore } from '@/auth/state'

@Component({
  components: { VictualForm }
})
export default class VictualEdit extends Vue {
  victualApi: VictualApi = victualFirebaseApi

  victual: VictualWithMeta = {
    id: '',
    name: '',
    nutriments: {
      energyInKcal: 0,
      fats: 0,
      proteins: 0,
      carbs: 0
    },
    pricePer100g: 0,
    allergens: [],
    category: undefined,
    supermarketDepartment: undefined,
    defaultAmountInGrams: 100,
    units: [],
    meta: {
      createdAt: new Date(),
      lastModifiedAt: new Date(),
      createdByUserId: 'unkown',
      lastModifiedByUserId: 'unkown',
      ownedByGroupId: 'unkown'
    }
  }

  victualValid = false
  loading = false

  groupName: string = ''
  isCurrentUserPartOfGroup = false

  async mounted (): Promise<void> {
    const id = this.$route.params.id

    this.loading = true
    this.victual = await this.victualApi.get(id)
    this.groupName = userStore.groupName(this.victual.meta.ownedByGroupId)
    this.isCurrentUserPartOfGroup = userStore.state.currentUser?.group === this.victual.meta.ownedByGroupId
    this.loading = false
  }

  async onUpdate (): Promise<void> {
    if (!this.victualValid) {
      return
    }
    const victual = Object.assign({}, this.victual)

    if (!this.isCurrentUserPartOfGroup) {
      await this.victualApi.updateOverwrite(victual.id, victual)
      await this.$router.push(`/victuals/${victual.id}`)
      return
    }

    await this.victualApi.update(victual.id, victual)
    await this.$router.push(`/victuals/${victual.id}`)
  }

  async onCancel (): Promise<void> {
    await this.$router.go(-1)
  }

  onValidChanged (valid: boolean): void {
    this.victualValid = valid
  }
}
