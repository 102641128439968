


























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Victual from '../model/victual'

@Component({})
export default class VictualPrintPreview extends Vue {
  
  @Prop()
  victual!: Victual

  private UNDEFINED_STR = this.$tc('undef')

  get printableAllergens (): string {
    const allergens = this.victual.allergens.map(allergen => {
      return this.$tc(`vic.allerg.${allergen}`)
    })
    const allergenesSorted = allergens.sort((x, y) => x.localeCompare(y))
  
    return allergenesSorted.join(', ')
  }

  get printableCategory (): string {    
    if (this.victual.category === undefined) {
      return this.UNDEFINED_STR
    }

    const transKey = `vic.cat.${this.victual.category}`
    const category = this.$tc(transKey)
    const noTranslationFound = category === transKey
    if (noTranslationFound) {
      return this.UNDEFINED_STR
    }

    return category
  }

  get printableSupermarketDepartment (): string {
    if (this.victual.supermarketDepartment === undefined) {
      return this.UNDEFINED_STR
    }

    const transKey = `vic.superm_dep.${this.victual.supermarketDepartment}`
    const department = this.$tc(transKey)
    const noTranslationFound = department === transKey
    if (noTranslationFound) {
      return this.UNDEFINED_STR
    }

    return department
  }
}
