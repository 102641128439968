


































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { userStore } from '@/auth/state'
import { SHOPPINGLIST_ADD_VICTUAL } from '@/shoppinglist/events'
import ActionIcon from '@/shared/components/ActionIcon.vue'
import VictualDeleteIcon from '../components/VictualDeleteIcon.vue'
import VictualPrintPreview from '../components/VictualPrintPreview.vue'

import VictualApi from '../api/api'
import { victualFirebaseApi } from '../api/firebaseApi'

import VictualUsage from '../components/VictualUsage.vue'
import Victual, { VictualWithMeta } from '../model/victual'
import { isDeletable } from '../authorization/victualAuthorization'

@Component({
  components: { VictualUsage, ActionIcon, VictualDeleteIcon, VictualPrintPreview }
})
export default class VictualInfo extends Vue {
  victualApi: VictualApi = victualFirebaseApi
  
  victualId: string = ''

  victual: VictualWithMeta = {
    id: '',
    name: '',
    nutriments: {
      energyInKcal: 0,
      fats: 0,
      proteins: 0,
      carbs: 0
    },
    pricePer100g: 0,
    allergens: [],
    category: undefined,
    supermarketDepartment: undefined,
    defaultAmountInGrams: 100,
    units: [],
    meta: {
      createdAt: new Date(),
      lastModifiedAt: new Date(),
      createdByUserId: 'unkown',
      lastModifiedByUserId: 'unkown',
      ownedByGroupId: 'unkown'
    }
  }

  loading = true

  groupName: string = ''
  isCurrentUserPartOfGroup = false
  isDeletable = false

  async mounted (): Promise<void> {
    this.victualId = this.$route.params.id

    this.loading = true

    this.victual = await this.victualApi.get(this.victualId)
    this.groupName = userStore.groupName(this.victual.meta.ownedByGroupId)
    this.isCurrentUserPartOfGroup = userStore.state.currentUser?.group === this.victual.meta.ownedByGroupId
    this.isDeletable = isDeletable(this.victual.meta)

    this.loading = false
  }

  async onBack (): Promise<void> {
    await this.$router.go(-1)
  }

  async onEdit (): Promise<void> {
    await this.$router.push(`/victuals/${this.victual.id}/edit`)
  }

  onPrint (): void {
    window.print()
  }

  async onDelete (): Promise<void> {
    await this.victualApi.delete(this.victual.id)
    await this.$router.push('/victuals/')
  }

  async onCopy (): Promise<void> {
    await this.$router.push(`/victuals/${this.victual.id}/copy`)
  }


  onToShoppinglist (): void {
    const event = new CustomEvent<Victual>(SHOPPINGLIST_ADD_VICTUAL, {
      detail: this.victual
    })
    window.dispatchEvent(event)
  }
}
