








































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'

import Victual, {
  VictualCategory,
  Allergen,
  SupermarketDepartment
} from '../model/victual'
import { PropSync, Emit, Watch, Prop } from 'vue-property-decorator'

@Component({})
export default class VictualForm extends Vue {
  valid = false

  @PropSync('value')
  victual!: Victual

  @Prop({ default: false })
  readonly loading!: boolean

  @Prop({ default: false })
  readonly overrideMode!: boolean

  @Emit('value')
  emitValue (): Victual {
    return this.victual
  }

  @Emit('valid')
  emitValid (): boolean {
    return this.valid
  }

  @Watch('victual', { deep: true })
  victualChanged (): void {
    this.emitValue()
  }

  @Watch('valid')
  validChanged (): void {
    this.emitValid()
  }

  get availableCategories (): Array<{ text: string, value: VictualCategory }> {
    return Object.values(VictualCategory).map(category => {
      return {
        text: this.$t('vic.cat.' + category) as string,
        value: category
      }
    })
  }

  get availableSupermarketDepartments (): Array<{ text: string, value: SupermarketDepartment }>  {
    return Object.values(SupermarketDepartment).map(department => {
      return {
        text: this.$t(
          'vic.superm_dep.' + department
        ) as string,
        value: department
      }
    })
  }

  get availableAllergens (): Array<{ text: string, value: Allergen }> {
    return Object.values(Allergen).map(allergen => {
      return {
        text: this.$t('vic.allerg.' + allergen) as string,
        value: allergen
      }
    })
  }

  removeUnit (index: number): void {
    this.victual.units.splice(index, 1)
    this.validate()
  }

  removeAllUnits (): void {
    this.victual.units = []
    this.validate()
  }

  addNewUnit (): void {
    this.victual.units.push({
      name: this.$root.$t('new') as string,
      oneUnitEqualsGrams: 100
    })
    this.validate()
  }

  validate (): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  requiredRule = (v: unknown): true | string => 
    (v !== null && v !== undefined) || this.$root.$t('validation.required') as string

  minNumberRules = [
    (v: Number): true | string => 
      (v !== null && v !== undefined) || this.$root.$t('validation.minNumber', { number: 0 }) as string,
    (v: number): true | string => v > 0 || this.$root.$t('validation.minNumber', { number: 0 }) as string
  ]

  posNumberRules = [
    (v: string): true | string => !Number.isNaN(parseFloat(v)) || this.$root.$t('validation.posNumber') as string,
    (v: number): true | string => v >= 0 || this.$root.$t('validation.posNumber') as string
  ]

  noDuplicateUnitRule = (v: string): true | string =>
    !this.unitExistsAlready(v) || this.$root.$t('validation.noDuplicates') as string

  nonEmptyStringRules = [
    (v: unknown): true | string => typeof v == 'string' || this.$root.$t('validation.stringNotEmpty') as string,
    (v: string): true | string => v.length > 0 || this.$root.$t('validation.stringNotEmpty') as string
  ]

  unitExistsAlready (v: string): boolean {
    return this.victual.units.filter(x => x.name === v).length > 1
  }
}
