








































































































import Vue from 'vue'
import Component from 'vue-class-component'

import { DataTableHeader } from 'vuetify'
import VictualSearchForm from '../components/VictualSearchForm.vue'
import ActionIcon from '@/shared/components/ActionIcon.vue'
import VictualDeleteIcon from '../components/VictualDeleteIcon.vue'

import Victual, { VictualCategory, VictualWithMeta } from '../model/victual'
import VictualApi from '../api/api'
import { victualFirebaseApi } from '../api/firebaseApi'
import { SHOPPINGLIST_ADD_VICTUAL } from '../../shoppinglist/events'
import { VictualSearch } from '../components/victualSearch'
import { isDeletable as isVictualDeletable } from '../authorization/victualAuthorization'

@Component({
  components: { VictualSearchForm, ActionIcon, VictualDeleteIcon },
  methods:  { isVictualDeletable }
})
export default class Victuals extends Vue {
  loading = false
  allLoaded = false
  timeoutId?: number

  search: VictualSearch = {
    searchTerm: '',
    categories: []
  }

  pageSize = 10
  headers: DataTableHeader[] = [
    {
      text: this.$root.$tc('table.name'),
      value: 'name',
      align: 'start',
      sortable: false
    },
    {
      text: this.$root.$tc('nutriments.energy'),
      value: 'nutriments.energyInKcal',
      sortable: false
    },
    {
      text: this.$root.$tc('vic.pricePer100g'),
      value: 'pricePer100g',
      sortable: false
    },
    {
      text: this.$root.$tc('table.actions'),
      value: 'actions',
      sortable: false,
      width: '120px'
    }
  ]

  allVictualCounter = 0
  items: VictualWithMeta[] = []
  victualApi: VictualApi = victualFirebaseApi
  debounceTime = 500

  mounted (): void {
    this.initializeFromUrl()

    void this.loadVictuals()
    void this.loadVictualCounter()

    this.$watch('search', this.triggerSearch, { deep: true })
  }

  triggerSearch (): void {
    clearTimeout(this.timeoutId)

    this.timeoutId = setTimeout(async () => {
      await this.loadVictuals()
    }, this.debounceTime)
  }

  async loadVictuals (): Promise<void> {
    this.allLoaded = false

    this.loading = true
    this.items = await this.victualApi.search(
      this.search.searchTerm,
      this.search.categories,
      {
        pageSize: this.pageSize,
        last: undefined
      }
    )
    this.loading = false
    void this.writeQueryParams()
  }

  async loadVictualCounter (): Promise<void> {
    this.allVictualCounter = await this.victualApi.counter()
  }

  async loadMore (): Promise<void> {
    if (this.items.length == 0) {
      return
    }

    this.loading = true
    const moreItems = await this.victualApi.search(
      this.search.searchTerm,
      this.search.categories,
      {
        pageSize: this.pageSize,
        last: this.items[this.items.length - 1]
      }
    )

    if (moreItems.length == 0) {
      this.allLoaded = true
    }

    this.items.push(...moreItems)
    this.loading = false
  }

  async createItem (): Promise<void> {
    this.allVictualCounter++
    await this.$router.push('/victuals/new')
  }

  async onInfo (item: Victual): Promise<void> {
    await this.$router.push(`/victuals/${item.id}`)
  }

  async deleteItem (item: Victual): Promise<void> {
    await this.victualApi.delete(item.id)
    this.items = this.items.filter(x => x.id != item.id)
    this.allVictualCounter--
  }

  async editItem (item: Victual): Promise<void> {
    await this.$router.push(`/victuals/${item.id}/edit`)
  }

  addToShoppinglist (item: Victual): void{
    const event = new CustomEvent<Victual>(SHOPPINGLIST_ADD_VICTUAL, {
      detail: item
    })
    window.dispatchEvent(event)
  }

  toEur = (val: number): string => {
    return `€ ${val.toFixed(2)}`
  }

  async writeQueryParams (): Promise<void> {
    const areSameCategories = this.search.categories.join(',') === this.getSearchCategoriesFromUrl().join(',')
    const areSameSearchTerm = this.search.searchTerm === this.getSearchTermFromUrl() 
    const noChanges = areSameCategories && areSameSearchTerm
    if (noChanges) {
      return
    }

    const query: {
      search?: string
      categories?: string
    } = {}
    if (this.search.searchTerm.length > 0) {
      query.search = this.search.searchTerm 
    }
    if (this.search.categories.length > 0) {
      query.categories = this.search.categories.join(',') 
    }
    
    await this.$router.replace({ query })
  }

  initializeFromUrl (): void {
    this.search.searchTerm = this.getSearchTermFromUrl()
    this.search.categories = this.getSearchCategoriesFromUrl()
  }

  getSearchTermFromUrl (): string {
    const queryParam = this.$route.query.search
    if (Array.isArray(queryParam)) {
      return queryParam[0] ?? ''
    } 
    return queryParam ?? ''
  }

  getSearchCategoriesFromUrl (): VictualCategory[] {
    const queryParam = this.$route.query.categories
    if (queryParam === undefined || queryParam === null || Array.isArray(queryParam)) {
      return []
    }   

    const categoriesAsStr = queryParam.split(',')

    return categoriesAsStr
      .map( c => c.toUpperCase())
      .map( c => VictualCategory[c as keyof typeof VictualCategory])
      .filter( c => c !== undefined)
  }
  
  get isEmptySearch (): boolean {
    return this.search.searchTerm.length === 0 && this.search.categories.length === 0 
  }

}
